// npm

// css
import '../../scss/pages/only-header.scss';

// libs
import Details from '../_libs/Details';
import Header from '../_libs/Header';
import { Modal } from '../_libs/Modal';

// utils

document.addEventListener('DOMContentLoaded', () => {});

window.addEventListener('load', () => {
	Header();

	// メニュー
	const menu = new Modal({
		globalContainer: '.l-main',
		html: 'is-menu-open',
		modal: '.js-menu-2024',
		open: '.js-menu-open-2024',
		close: '.js-menu-close-2024',
		openFunc: () => {
			document.querySelector('.l-main').classList.add('active');
		},
		closeFunc: () => {
			document.querySelector('.l-main').classList.remove('active');
		},
	});
	menu.init();

	// メニュー（アコーディオン）
	new Details('.js-menu-accordion', {
		head: '.js-menu-accordion-head',
		body: '.js-menu-accordion-body',
	});
});
